import React from 'react';
import { HiArrowNarrowRight } from 'react-icons/hi';
import { Link } from 'react-scroll';


const Hero = () => {
  return (
    <div name='home' className='w-full h-[100vh]'>
      {/* Container */}
      <div className='max-w-[1000px] mx-auto px-10 flex flex-col justify-center h-full'>
        <p className='text-black-600 dark:text-pink-600'>Hi, I'm</p>
        <h1 className='text-4xl sm:text-7xl font-bold dark:text-[#ccd6f6] text-yellow-500'>
          Yitzhak Alvarez
        </h1>
        <p className='text-[black] py-4 max-w-[700px] dark:text-[yellow]'>
          I’m a full-stack developer based in New York City with a focus on front-end development.
        </p>
        <div>
        <Link to='about' smooth={true} duration={500}>
          <button className='dark:text-white text-black group border-2 px-6 py-3 my-2 flex items-center dark:hover:bg-pink-600 dark:hover:border-pink-600 hover:bg-orange-500 hover:border-orange-500'>
            View More
            <span className='group-hover:rotate-90 duration-300'>
              <HiArrowNarrowRight className='ml-3 ' />
            </span>
          </button>
        </Link>
        </div>
      </div>
    </div>
  );
};

export default Hero;