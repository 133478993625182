// --------------------------------------- Logo ---------------------------------------
export const logoList =
{
    name: 'Yitzhak Alvarez',
};

// --------------------------------------- Headshot ---------------------------------------
export const portraitList = [
    {
        img: require("../assets/img/headshot.png"),
        name: "Yitzhak Alvarez",
    },
];

// --------------------------------------- Project List ---------------------------------------
export const ProjectList = [
    {
        img: require("../assets/img/projects/SPRC Website/SPRC - thumbnail.png"),
        title: 'Sponsored Programs & Research Compliance Website',
        description: 'Designed and built the website for the Dept. of Sponsored Programs and Research Compliance at SUNY New Paltz',
        role: 'Frontend Developer',
        techstack: 'HTML/CSS, Bootstrap, JavaScript, Figma, TerminalFour',
        demo_url: 'https://yitzhakalvarez.github.io/SPRC-WebsiteRevamp/',
        github_url: 'https://github.com/yitzhakalvarez/SPRC-WebsiteRevamp',
    },
    {
        img: require("../assets/img/projects/Fitness Tracker/Fitness Tracker - thumbnail.png"),
        title: 'Fitness Tracker',
        description: 'A web app that tracks your fitness and calories.',
        techstack: 'HTML/CSS, JavaScript, Vue.js, Node.js, Express.js, MySQL, Figma, Bulma',
        role: 'Full Stack Engineer',
        demo_url: 'http://fitness-trak.herokuapp.com/',
        github_url: 'https://github.com/yitzhakalvarez/FitnessTracker',
    },
    {
        img: require("../assets/img/projects/Smart Library/Smart Library - thumbnail.png"),
        title: 'Smart Library',
        description: 'The Smart Library is a large project with the goal of creating a library experience online that mimics the experience in person.',
        role: 'Frontend Developer',
        techstack: 'HTML/CSS, Bootstrap, JavaScript, jQuery, PHP, MySQL, Python, Figma',
        demo_url: 'https://cs.newpaltz.edu/p/s21-01/smart-library/',
        github_url: 'https://github.com/noah-franklin/smart-library',
    },

];


// --------------------------------------- Experience List ---------------------------------------
export const workExperiences = [
    {
        role: "Assistant Project and Web Development Manager",
        company: "GCNY Marketing",
        companylogo: require("../assets/img/experience/GCNY.jpg"),
        location: "Brooklyn, NY",
        date: "December 2023 – Present",
    },
    {
        role: "Full Stack Developer",
        company: "Alter Learning - Educational Platform",
        companylogo: require("../assets/img/experience/alter-learning.png"),
        location: "Davis, CA",
        date: "December 2023 – Present",
    },
    {
        role: "Frontend Developer",
        company: "Optima Audience",
        companylogo: require("../assets/img/experience/optima-audience.webp"),
        location: "New York, NY",
        date: "November 2022 – June 2023",
    },
    {
        role: "Software Engineer Intern",
        company: "Grassroots Grocery",
        companylogo: require("../assets/img/experience/grassrootsgrocery-logo.jpeg"),
        location: "Bronx, NY",
        date: "September 2021 – December 2021",
    },
    {
        role: "Web Developer & Web Designer",
        company: "Sponsored Programs & Research Compliance",
        companylogo: require("../assets/img/experience/SUNY_New_Paltz_Logo.png"),
        location: "New Paltz, NY",
        date: "October 2020 – August 2021",
    },
    {
        role: "Social Media Manager",
        company: "New Paltz Economics Club",
        companylogo: require("../assets/img/experience/npecon-logo.png"),
        location: "New Paltz, NY",
        date: "February 2021 – May 2021",
    },
    {
        role: "Software Engineer Intern",
        company: "Sojourner Truth Library",
        companylogo: require("../assets/img/experience/stl_logo.jpeg"),
        location: "New Paltz, NY",
        date: "January 2021 – May 2021",
    },
    {
        role: "Marketing & Web Developer Intern",
        company: "FALA Technologies Inc.",
        companylogo: require("../assets/img/experience/Fala.jpg"),
        location: "Kingston, NY",
        date: "September 2020 – December 2020",
    }, {
        role: "Full Stack Developer Intern",
        company: "One EPIC Place",
        companylogo: require("../assets/img/experience/oneepicplace-logo.png"),
        location: "New Paltz, NY",
        date: "September 2019 – December 2019",
    },
];

// --------------------------------------- Skills ---------------------------------------

export const stackList = [
    {
        img: require("../assets/img/skills/HTML.png"),
        name: "HTML",
    },
    {
        img: require("../assets/img/skills/CSS.png"),
        name: "CSS",
    },
    {
        img: require("../assets/img/skills/JS.png"),
        name: "JavaScript",
    },
    {
        img: require("../assets/img/skills/python.png"),
        name: "Python",
    },
    {
        img: require("../assets/img/skills/bootstrap.png"),
        name: "Bootstrap",
    },
    {
        img: require("../assets/img/skills/java.png"),
        name: "Java",
    },
    {
        img: require("../assets/img/skills/git.png"),
        name: "Git",
    },
    {
        img: require("../assets/img/skills/react.png"),
        name: "React",
    },
    {
        img: require("../assets/img/skills/Gatsby_Monogram.png"),
        name: "Gatsby",
    },
    {
        img: require("../assets/img/skills/GraphQL Logo (Rhodamine).png"),
        name: "GraphQL",
    },
    {
        img: require("../assets/img/skills/vue.png"),
        name: "Vue.js",
    },
    {
        img: require("../assets/img/skills/node.png"),
        name: "Node.js",
    },
    {
        img: require("../assets/img/skills/photoshop.png"),
        name: "Photoshop",
    },
    {
        img: require("../assets/img/skills/lightroom.png"),
        name: "Lightroom",
    },
    {
        img: require("../assets/img/skills/figma.png"),
        name: "Figma",
    },
    {
        img: require("../assets/img/skills/excel.png"),
        name: "Excel",
    },
    {
        img: require("../assets/img/skills/canva.png"),
        name: "Canva",
    },
    {
        img: require("../assets/img/skills/mysql.png"),
        name: "MySQL",
    },
    {
        img: require("../assets/img/skills/sql.png"),
        name: "SQL",
    },
    {
        img: require("../assets/img/skills/postman.png"),
        name: "Postman",
    },
    {
        img: require("../assets/img/skills/expressjs.png"),
        name: "Express.js",
    },
    {
        img: require("../assets/img/skills/php.png"),
        name: "PHP",
    },
    {
        img: require("../assets/img/skills/trello.png"),
        name: "Trello",
    },
    {
        img: require("../assets/img/skills/airtable.png"),
        name: "Airtable",
    },
    {
        img: require("../assets/img/skills/WordPress.png"),
        name: "WordPress",
    },
    {
        img: require("../assets/img/skills/tailwind.png"),
        name: "Tailwind CSS",
    },
];