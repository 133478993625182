import React from 'react'

const Contact = () => {
  return (
    <div name='contact' className='w-full h-screen flex justify-center items-center p-4'>
        <form method='POST' action="https://formspree.io/f/mknyzoke" className='flex flex-col max-w-[600px] w-full'>
            <div className='pb-8'>
            <div className="Title font-bold inline border-b-4 dark:border-pink-600 border-pink-600 dark:text-white text-black">CONTACT</div>
            </div>
            <input className='text dark:bg-[#ffffff] bg-[#dfdcdc] my-2 p-2' type="text" placeholder='Name' name='name' />
            <input className='text dark:bg-[#ffffff] bg-[#dfdcdc] my-2 p-2' type="subject" placeholder='Subject' name='subject' />
            <input className='text dark:bg-[#ffffff] bg-[#dfdcdc] my-2 p-2' type="email" placeholder='Email' name='email' />
            <textarea className='text dark:bg-[#ffffff] bg-[#dfdcdc] my-2 p-2' name="message" rows="10" placeholder='Message'></textarea>
            <button className='dark:text-white text-black border-2 font-bold dark:hover:bg-pink-600 dark:hover:border-pink-600 hover:bg-orange-500 hover:border-orange-500 px-4 py-3 my-8 mx-auto flex items-center'>SEND</button>
        </form>
    </div>
  )
}
export default Contact