import styled from "styled-components";

export const ContactWrapper = styled.div`
  margin-top: 2rem;
`;

export const Headshot = styled.div`
  max-width: 120px;
  margin: 0 auto;
`;

export const PortraitImg = styled.div`
align-items: center;
`;

export const HeadImg = styled.img`
height: 100px;
`;

export const Technologies = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-right: auto;
  margin-left: auto;
  margin-bottom: -2rem;
`;

export const Tech = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 100px;
  min-width: 100px;
  margin-bottom: 2rem;
`;

export const TechImg = styled.img`
  height: 50px;
  width: 55px;
`;

export const TechName = styled.div`
  font-size: 14px;
`;