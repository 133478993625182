import Navbar from "./components/sections/Header/Navbar";
import Hero from "./components/sections/Hero/Hero";
import About from "./components/pages/About/About";
import WorkExperience from "./components/pages/Experience/WorkExperience";
import Projects from "./components/pages/Portfolio/Projects";
import Contact from "./components/pages/Contact/Contact";
import Footer from "./components/sections/Footer/Footer";
import ScrollToTopButton from "./components/sections/BacktoTop/Top"

function App() {
  return (
    <div>
      <Navbar />
      <Hero />
      <About />
      <WorkExperience />
      <Projects />
      <Contact />
      <Footer />
      <ScrollToTopButton />
    </div>
  );
}

export default App;