import React from 'react';
import { ProjectList } from '../../../data/info';
import {
  Card,
  CardLeft,
  CardRight,
  Stack,
  BtnGroup,
} from "./ProjectsElements";

const Projects = () => {
  return (
  <div name='portfolio'>
    <div className='max-w-[1000px] mx-auto p-4 flex flex-col justify-center w-full h-full dark:text-white text-black'>
      <div className='pb-8'>
        <div className="Title">PORTFOLIO</div>
      </div>
      {ProjectList.map((list, index) => (
      <Card key={index}>
        <CardLeft> <img src={list.img} alt={list.name} /> </CardLeft>
        <CardRight>
          <h4>{list.title}</h4>
          <p>{list.description}</p>
          <Stack>
            <span className="stackTitle">Role</span>
            <span className="tags">{list.role}</span>
            </Stack>
          <Stack>
            <span className="stackTitle">Tech</span>
            <span className="tags">{list.techstack}</span>
            </Stack>
            <BtnGroup>
              <a
              className="btn btn2 SecondarBtn"
              href={list.github_url}
              target="_blank"
              rel="noopener noreferrer"
              >
                Github
                </a>
              <a
              className="btn PrimaryBtn"
              href={list.demo_url}
              target="_blank"
              rel="noopener noreferrer"
              >
                Demo ➜
              </a>
            </BtnGroup>
        </CardRight>
      </Card>
      ))}
      </div>
      </div>
  );
};

export default Projects;