import React from "react";
import ExperienceCard from "./ExperienceCard";
import { workExperiences } from '../../../data/info';
import "./Experience.css";

function WorkExperience() {
    return (
      <div id="experience">
          <div className="experience-container" id="workExperience">
            <div>
          <div className=' dark:text-white text-black'>
            <div className='pb-8'>
              <div className="Title">EXPERIENCE</div>
              </div>
              </div>
              <div className="experience-cards-div">
                {workExperiences.map((card, i) => {
                  return (
                    <ExperienceCard
                      key={i}
                      cardInfo={{
                        company: card.company,
                        date: card.date,
                        companylogo: card.companylogo,
                        location: card.location,
                        role: card.role,
                      }}
                    />
                  );
                })}
              </div>
            </div>
          </div>
      </div>
    );
}

export default WorkExperience;