import React from "react";
import { stackList, portraitList }  from "../../../data/info";
import {
  Headshot,
  PortraitImg,
  HeadImg,
  Technologies,
  Tech,
  TechImg,
  TechName,
  ContactWrapper,
} from "./AboutElements";

function About() {
  return (
    <ContactWrapper id="about">
      <div className="Container dark:text-white text-black">
        <div className="Title">ABOUT</div>
        <Headshot>
            {portraitList.map((head, index) => (
              <PortraitImg key={index} className="photo">
                <HeadImg src={head.img} alt={head.name} />
              </PortraitImg>
            ))}
          </Headshot>
          <div className="AboutBio dark:text-white">
          Yitzhak Alvarez is a hard-working and dedicated student and a leader who strives for success and loves to
              try out new things.
              He is an aspiring full-stack developer. He is a recent graduate and alumni of the
              State University of New York at New Paltz.
              He graduated with a Bachelor of Science degree in Computer Science with a minor in Economics on May 14, 2021.
              During his spare time, Yitzhak has been involved in clubs to help him reach his goals.
              He was an active member of the National Society of Black Engineers (NSBE), Engineering Club, Society
              of Hispanic Professional Engineers (SHPE), and was a Social Media Manager for the New Paltz Economics Club.
              Yitzhak interned for One EPIC Place in New Paltz as a Full Stack Engineer, where he created a full-stack
              web application that allows clients to rent rooms from the company.
          </div>
          <div className="AboutBio tagline2 font-bold dark:text-white">
            My Tech Stack:
          </div>
          <Technologies>
            {stackList.map((stack, index) => (
              <Tech key={index} className="tech">
                <TechImg src={stack.img} alt={stack.name} />
                <TechName>{stack.name}</TechName>
              </Tech>
            ))}
          </Technologies>
        </div>
    </ContactWrapper>
  );
}

export default About;